window.addEventListener('DOMContentLoaded', () => {
    const sortingOrdersSelectorId = 'sorting_orders';
    if (document.getElementById(sortingOrdersSelectorId)) {
        (() => new vanillaSelectBox(`#${sortingOrdersSelectorId}`,
            {
                maxHeight: 200,
            }))();
    }

    const typesSelectorId = 'types';
    if (document.getElementById(typesSelectorId)) {
        (() => new vanillaSelectBox(`#${typesSelectorId}`,

            {
                maxHeight: 200,
                translations: {items: 'types'},
                disableSelectAll: true,
            }))();
    }
}, false);
